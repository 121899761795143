/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

export default [
  {
    path: '',
    view: 'OnePage',
    meta: {
      title: 'Home',
      description: '',
      keywords: ''
    }
  },
  {
    path: 'privacyPolicy',
    view: 'PrivacyPolicy'
  },
  {
    path: 'informationSecurityPolicy',
    view: 'SecurityPolicy'
  },
  {
    path: 'whistleBlowing',
    view: 'Prospectus'
  },
  {
    path: 'documents',
    view: 'Documents'
  }
]
