<template>
  <v-footer dark>
    <v-card style="background: transparent" width="100%">
      <v-card-text class="white--text pa-0">
        <v-container class="pa-0">
          <v-row :align="'center'">
            <v-col md="2" class="pa-5">
              &copy; 2023
              {{ $t('company') }}
              <br />
              {{ $t('rights') }}
            </v-col>
            <v-col md="9">
              <!--<v-row :align="'center'">
                <v-col style="padding-left: 6px; padding-right: 6px">
                  <v-btn
                    draggable="false"
                    text
                    min-width="0"
                    v-if="$t('privacyPolicyTitle')"
                    @click="$vuetify.goTo('#page-top')"
                    to="privacyPolicy"
                    >{{ $t('privacyPolicyTitle') }}</v-btn
                  >
                </v-col>
                <v-col style="padding-left: 6px; padding-right: 6px">
                  <v-btn
                    draggable="false"
                    text
                    min-width="0"
                    v-if="$t('whistleBlowingTitle')"
                    @click="$vuetify.goTo('#page-top')"
                    to="whistleBlowing"
                    >{{ $t('whistleBlowingTitle') }}</v-btn
                  >
                </v-col>
                <v-col style="padding-left: 6px; padding-right: 6px">
                  <v-btn
                    draggable="false"
                    text
                    min-width="0"
                    v-if="$t('informationSecurityPolicy')"
                    @click="$vuetify.goTo('#page-top')"
                    to="informationSecurityPolicy"
                    >{{ $t('informationSecurityPolicy') }}</v-btn
                  >
                </v-col>
                -->
                <!--the v-if is only there because of language translation. If the v-if is not there, the link will appear in English version, too.-->
                <!--<v-col style="padding-left: 6px; padding-right: 6px">
                  <v-btn
                    draggable="false"
                    text
                    min-width="0"
                    v-if="$t('informationSecurityPolicy')"
                    @click="openPdfInNewTab('/antikorrupcios_szabalyzat.pdf')"
                    >Antikorrupciós szabályzat</v-btn
                  >
                </v-col>
                -->
                <!--the v-if is only there because of language translation. If the v-if is not there, the link will appear in English version, too.-->
                <!--<v-col style="padding-left: 6px; padding-right: 6px">
                  <v-btn
                    draggable="false"
                    text
                    min-width="0"
                    v-if="$t('informationSecurityPolicy')"
                    @click="openPdfInNewTab('/magatartasi_kodex.pdf')"
                    >Magatartási kódex</v-btn
                  >
                </v-col>
              </v-row>
              -->
            </v-col>
            <v-col md="1" class="pa-0">
              <p class="text-right ma-0">
                <img alt="iso" src="../../assets/iso-1.png" />
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>

export default {
  methods: {
  openPdfInNewTab(pdfUrl) {
       
    window.open(pdfUrl, '_blank');
  }
}
}

</script>


<style scoped>
.v-footer {
  min-height: 112px;
}
.v-footer .v-card {
  box-shadow: none !important;
}
</style>